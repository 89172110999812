import React from 'react';

import BackToHomepageRedirect from '../common/BackToHomepage/BackToHomepageRedirect';
import ComingSoon from '../common/ComingSoon/ComingSoon';
import './WorkPageContent.css';

const WorkPageContent = () => {
    return (
        <>
            <ComingSoon />
            <p className="subtitle-work-content center-item">Work section is still under construction.</p>
            <BackToHomepageRedirect />
        </>
    )
}

export default WorkPageContent;