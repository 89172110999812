import * as React from "react"

import WorkPageContent from "../components/WorkPage/WorkPageContent"
import Layout from "../components/layout"
import Seo from "../components/seo"

const WorkPage = () => (
  <Layout>
    <Seo title="Work" />
    <WorkPageContent />
  </Layout>
)

export default WorkPage
